import { useState, useEffect, useContext } from "react";
import DeleteButton from "../common/DeleteButton";
import SettingContext from "Providers/SettingContext";
import MeasurementService from "../common/MeasurementService";
import SensorDetailDialog from "../thingDetail/SensorDetailDialog";
import _ from "underscore";
import Coordinator from "../common/Coordinator";
import ExportMenu from "Components/grid/ExportMenu";
import UserService from "Services/userService";
import moment from "moment";
import WidgetViewMain from "../common/WidgetViewMain";
import { useTranslation } from "react-i18next";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, useGridApiRef } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { createData } from "./createData";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function MeasurementView({ keyword, appId, callback, viewMode }) {
    const { t, i18n } = useTranslation();
    const setting = useContext(SettingContext);

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [allColumns, setAllColumns] = useState([]);

    const [on, setOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedThingId, setSelectedThingId] = useState();
    const [isOpenThingDetail, setIsOpenThingDetail] = useState(false);
    const [sortQuery, setSortQuery] = useState("");
    const [reload, setReload] = useState();
    const [user, setUser] = useState();
    const [sortModel, setSortModel] = useState([]);

    const apiRef = useGridApiRef();

    useEffect(() => {
        // console.log("uef in MeasurementView");

        const allCols = _.union(Coordinator.getFixeColumns(t), Coordinator.getDynamicColumns());
        setAllColumns(allCols);

        UserService.getAuthenticatedUser().then((u) => {
            let timeZone = u.zoneinfo;
            if (!timeZone) {
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }

            if (!user) {
                setUser({
                    cognitoUserName: u.username,
                    zoneinfo: timeZone,
                    authorities: u.authorities,
                    company: u.company,
                    email: u.email,
                    firstName: u.firstName,
                    lastName: u.lastName,
                });
            }
        });

        let qParams = [];
        if (sortModel) {
            _.each(sortModel, function (sm) {
                let result = "";

                switch (sm.field) {
                    case "thing":
                        result += "displayName";
                        break;
                    case "timestamp":
                        result += "lastMessageDate";
                        break;
                    case "model":
                        result += "custom_model.name";
                        break;
                    case "product":
                        result += "product.name";
                        break;
                    case "status":
                        result += "status";
                        break;
                    case "tags":
                        result += "thing_tag_view.agg_tag";
                        break;
                    case "size":
                        result += "watermeter_size_view.size_value";
                        break;
                    case "radio":
                        result += "watermeter_radio_view.radio_value";
                        break;
                    case "unit":
                        result += "watermeter_unit_view.unit_value";
                        break;
                    default:
                        break;
                }

                switch (sm.sort) {
                    case "asc":
                        result += "&dir=ASC";
                        break;
                    case "desc":
                        result += "&dir=DESC";
                        break;
                    default:
                        break;
                }

                qParams.push(result);
            });
        }

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        MeasurementService.requestData(
            createData,
            paginationModel.page,
            paginationModel.pageSize,
            setRows,
            allCols,
            setColumns,
            keyword,
            sortQuery,
            on,
            appId,
            setRowCount
        ).then((r) => {
            const savedVisibility = Coordinator.getColumnVisibility();
            if (savedVisibility) {
                for (const key in savedVisibility) {
                    const value = savedVisibility[key];
                    if (apiRef.current) {
                        apiRef.current.setColumnVisibility(key, value);
                    }
                }
            }
        });
    }, [keyword, paginationModel.page, paginationModel.pageSize, on, sortModel, viewMode, reload]);

    const handleSortModelChange = (param) => {
        // console.log(param);
        setSortModel(param);
    };

    const handleReport = (reportType) => {
        // console.log(user.cognitoUserName)
        MeasurementService.requestReport(paginationModel.page, 1000, keyword, sortQuery, reportType);
    };

    const handleColumnOrderChange = (param) => {
        Coordinator.saveColumnOrder(param);
    };

    const handleColumnVisibilityChange = (model) => {
        Coordinator.saveColumnVisibility(model);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
                <ExportMenu callback={handleReport} />
                {/* <ToggleButton callback={handleToggle} on={on}></ToggleButton> */}
                <DeleteButton callback={handleRefresh} selectionModel={selectionModel}></DeleteButton>
            </GridToolbarContainer>
        );
    }

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
    };

    const handleRefresh = () => {
        setRows([]);
        setRowCount(0);
        setReload(moment().unix());
        setSelectionModel();
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }

        setIsOpenThingDetail(true);
        setSelectedThingId(params.row.id);
    };

    const closeDialog = () => {
        // console.log("closeDialog");
        setIsOpenThingDetail(false);
        setReload(moment().unix());

        callback();
        // window.location.reload();
    };

    return (
        <>
            {viewMode === "grid" && (
                <DataGridPro
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    rowCount={rowCount}
                    disableColumnFilter
                    pagination={true}
                    paginationMode="server"
                    sortingMode="server"
                    paginationModel={paginationModel}
                    pageSizeOptions={[20, 50, 100]}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                    disableRowSelectionOnClick
                    density="compact"
                    onCellClick={handleCellClick}
                    disableSelectionOnClick
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                serialNumber: false,
                                company: false,
                                model: false,
                                manufacturer: false,
                                product: false,
                                connectivityType: false,
                                id_battery: false,
                            },
                        },
                    }}
                    onColumnOrderChange={handleColumnOrderChange}
                    onColumnVisibilityModelChange={(model) => handleColumnVisibilityChange(model)}
                />
            )}

            {viewMode === "widget" && (
                <WidgetViewMain
                    rows={rows}
                    rowCount={rowCount}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    user={user}
                    callback={(thingId) => {
                        setIsOpenThingDetail(true);
                        setSelectedThingId(thingId);
                    }}
                />
            )}

            <SensorDetailDialog
                open={isOpenThingDetail}
                setOpen={setIsOpenThingDetail}
                callback={closeDialog}
                appId={appId}
                isAdmin={false}
                selectedThingId={selectedThingId}
                user={user}
            ></SensorDetailDialog>
        </>
    );
}
