import { useState, useEffect } from "react";
import DetailService from "./DetailService";
import Grid from "@mui/material/Grid";
import Item from "../../../../components/ui/Item";
import Typography from "@mui/material/Typography";
import UtilService from "Services/utilService";

export default function LastMeterRead({ selectedThingId, selectedProduct }) {
    const [lastMeterRead, setLastMeterRead] = useState();

    useEffect(() => {
        // console.log("uef in LastMeterRead");

        DetailService.requestLastMeasureByAttribute(selectedThingId, selectedProduct, setLastMeterRead);
    }, [selectedThingId, selectedProduct]);

    return (
        <>
            {lastMeterRead && lastMeterRead.error && (
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ height: "180px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="body1" gutterBottom>
                            No last meter read
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {lastMeterRead && !lastMeterRead.error && (
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display: "flex", textAlign: "left" }}>
                        <Item elevation={0}>
                            <Typography variant="subtitle1">Last meter read</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} style={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Item elevation={0}>
                            <Typography variant="h4">
                                {lastMeterRead.value} {lastMeterRead.attribute.unit}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item elevation={0}>
                            <Typography variant="subtitle2">{UtilService.shortestFormateDateMDYHMS(lastMeterRead.timestamp * 1000)}</Typography>
                        </Item>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
