import ApiService from "Services/apiService";
import UserService from "Services/userService";
import { v4 as uuidv4 } from "uuid";
import _ from "underscore";

const AlertService = {
    // state : IGNORED, RESOLVED
    updateAlertState: async (selectedIds, comment, state, reload) => {
        let preDefinedConfig = {
            url: "/api/alerts/" + selectedIds[0],
            data: {
                id: selectedIds[0],
                comment: comment,
                state: state,
            },
        };

        ApiService.putData(preDefinedConfig)
            .then((response) => {
                // console.log(response);

                reload(true);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    getEnableGenerateByCompany: async (setOn) => {
        let requestOptions = {
            url: `/api/alerts/getEnableGenerateByCompany/CENGN`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                setOn(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    getEnableGenerateByThingId: async (setOn, thingId) => {
        let requestOptions = {
            url: `/api/alerts/getEnableGenerateByThing/${thingId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                setOn(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    enableGenerate: async (enable, thingIds) => {
        let requestOptions = {
            url: `/api/alerts/enableGenerate`,
            data: {
                enableAlert: enable,
                company: "CENGN",
            },
        };

        if (thingIds != undefined) {
            requestOptions.data.thingIds = thingIds;
        }

        return ApiService.postData(requestOptions)
            .then((response) => {
                // do nothing
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestAlerts: async (page, rowsPerPage, createData, setRows, setRowCount, thingId, attributeId, sortModel, filterState, connectivity) => {
        const sortElement = sortModel.length && "&sort=" + sortModel.map((s) => `${s.sort === "asc" ? "+" : "-"}${s.field}`).join(",");

        const company = await UserService.getAuthenticatedUser().then((user) => user.company);
        let filter = `filter=company.name:eq_${company}`;

        // let filterElement = '';
        if (filterState) {
            filter += `,state:eq_${filterState}`;
        }

        let requestOptions = {
            url: `/api/alerts?pageSize=${rowsPerPage}&page=${page}&${sortElement}&${filter}`,
        };

        let allThings;
        if (thingId) {
            requestOptions.url += `,thing.id:eq_${thingId}`;
        } else {
            allThings = await AlertService.allThings(connectivity);
            // console.log(allThings);
        }

        if (attributeId) {
            requestOptions.url += `,alert.attributeId:eq_${attributeId}`;
        }

        if (allThings != undefined && allThings.length > 0) {
            let thingQuery = [];
            for (var i = 0; i < allThings.length; i++) {
                thingQuery.push(`{"property":["thing.id"],"operator":"eq","value":"${allThings[i].thingId}"}`);
            }
            let orFilter = thingQuery.join(",");

            requestOptions.url += encodeURI(`&orFilter=[${orFilter}]`);
        }

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                let totalSize = response.totalSize;
                let values = [];

                response.data.forEach((ele) => {
                    values.push(
                        createData(
                            ele.id,
                            ele.creationDate,
                            ele.level,
                            ele.message,
                            ele.thing.displayName,
                            ele.comment,
                            ele.lastUpdate,
                            ele.state,
                            ele.username
                        )
                    );
                });

                setRowCount(totalSize);
                setRows(values);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    allThings: async (connectivity) => {
        let requestOptions = {
            url: `/api/asset_thing/${connectivity}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                let thingIds = [];
                response.forEach((entity) => {
                    thingIds.push({ thingId: entity.thing.id });
                });
                return thingIds;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default AlertService;
