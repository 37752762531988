import React, { useEffect } from "react";
import { Box } from "@mui/material";
import SuperviotConstants from "../SuperviotConstants";
import AttributeGrid from "./AttributeGrid";

export default function AlarmMain() {
    useEffect(() => {
        // console.log("uef in AlarmMain");
    }, []);

    return (
        <Box sx={{ pt: 7, ml: 2, mr: 2, pb: 4 }}>
            {SuperviotConstants.data
                // .filter((s) => s.name === "FMPO")
                .map((s) => {
                    return <AttributeGrid key={s.thingId} siteName={s.name} thingId={s.thingId} />;
                })}
        </Box>
    );
}
