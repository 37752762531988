import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import _ from "underscore";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { AttributeColumns } from "./AttributeColumns";
import AlarmService from "./AlarmService";
import SuperviotConstants from "../SuperviotConstants";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function AttributeGrid({ siteName, thingId }) {
    const { t, i18n } = useTranslation();

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [columns, setColumns] = useState(AttributeColumns(t));

    // const columns = BoilerColumns(t);

    useEffect(() => {
        AlarmService.requestThing(thingId).then((r) => {
            // console.log(r.lastActivityDate);

            if (r.lastActivityDate) {
                AlarmService.requestData(t, thingId, setColumns, setRows, r.lastActivityDate);
            }
        });
    }, []);

    const handleSelectionModel = (model) => {
        // console.log("handleSelectionModel ...");
        // console.log(model);
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
                {/* <ExportMenu callback={handleReport} /> */}
                {/* <ToggleButton callback={handleToggle} on={on}></ToggleButton> */}
                {/* <DeleteButton callback={() => setRefresh(moment())} selectionModel={selectionModel}></DeleteButton> */}
            </GridToolbarContainer>
        );
    }
    const handleColumnOrderChange = (param) => {};

    // const columns = BoilerColumns(t);

    return (
        <Box
            sx={{
                mt: 3,
                "& .super-app.RED": {
                    backgroundColor: SuperviotConstants.RED,
                    color: "#FFFFFF",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.ORANGE": {
                    backgroundColor: SuperviotConstants.ORANGE,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.YELLOW": {
                    backgroundColor: SuperviotConstants.YELLOW,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.GREEN": {
                    backgroundColor: SuperviotConstants.GREEN,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.BLUE": {
                    backgroundColor: SuperviotConstants.BLUE,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.WHITE": {
                    backgroundColor: SuperviotConstants.WHITE,
                    color: "#000000",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {/* <Typography variant="h6" gutterBottom component="div">
                        {siteName}
                    </Typography> */}
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{siteName}</span>
                </Grid>
                <Grid item xs={12}>
                    <DataGridPro
                        columns={columns}
                        rows={rows}
                        rowCount={rowCount}
                        disableColumnFilter
                        pagination={false}
                        paginationMode="server"
                        sortingMode="server"
                        sortModel={sort}
                        paginationModel={pagination}
                        onPaginationModelChange={setPagination}
                        // pageSizeOptions={[20, 50]}
                        onSortModelChange={setSort}
                        // style={{ height: "calc(100vh - 80px)" }}
                        // slots={{
                        //     toolbar: CustomToolbar,
                        //     loadingOverlay: LinearProgress,
                        // }}
                        checkboxSelection={false}
                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        onCellClick={handleCellClick}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                            // "& .MuiDataGrid-columnHeaderTitle": {
                            //     whiteSpace: 'wrap',
                            //     textOverflow: 'clip',
                            //     lineHeight: '1.2em',
                            // },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                whiteSpace: "normal",
                                lineHeight: "normal",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                height: "unset !important",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                maxHeight: "168px !important",
                            },
                            "& .MuiDataGrid-columnHeaderTitleContainer": {
                                justifyContent: "center",
                                display: 'flex',
                            },
                        }}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        onColumnOrderChange={handleColumnOrderChange}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                        hideFooter={true}
                        disableColumnMenu={true}
                        columnHeaderHeight={60}
                        autoHeight={true}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
