import React, { useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import Box from "@mui/material/Box";
import useImage from "use-image";
import ImgFront from "Assets/icons/group-icon.png";
import ImgCompost from "Assets/images/Tahiti_Island.png";
import IslandKonvaImage from "./IslandKonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import { AppId } from "Components/applications/ApplicationData";
import ImageService from "Services/imageService";
import { useHistory } from "react-router-dom";
import SuperviotConstants from "../SuperviotConstants";

export default function IslandMap({ data, callback }) {
    const history = useHistory();
    const [imgFront] = useImage(ImgFront);
    const [imgCompost] = useImage(ImgCompost);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [code, setCode] = useState();

    const [backgroundImage, setBackgroundImage] = useState();

    useEffect(() => {
        // console.log("useEffect in IslandMap");
        // console.log(data);

        let newWidth, newHeight, newImageCode;
        newWidth = 764;
        newHeight = 543;
        newImageCode = AppId.SUPERVIOT + "_bg_image";
        setBackgroundImage(imgCompost);

        if (!backgroundImage && newImageCode !== code) {
            setWidth(newWidth);
            setHeight(newHeight);
            setCode(newImageCode);

            ImageService.requestBackgroundImage(newImageCode, setBackgroundImage).then((r) => {
                if (r) {
                    const imgElement = document.createElement("img");
                    imgElement.src = "data:image/png;base64," + r;
                    setBackgroundImage(imgElement);
                }
            });
        }
    }, [data, backgroundImage, code, imgCompost]);

    const handleImage = (e) => {
        ImageService.uploadBackgroundImage(code, e.target.files[0], setBackgroundImage).then((response) => {
            setCode(undefined);
            setBackgroundImage(undefined);
        });
    };

    const handleCallback = (name) => {
        callback(name);

        // const entity = SuperviotConstants.data.find((d) => d.name == name);
        // console.log(`/apps/superviot/detail/${entity.name}`);

        // window.open(`/apps/superviot/detail/${entity.name}`, "_blank", "noreferrer");
        // history.push(`/apps/superviot/detail/${entity.name}`);
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 10, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Stage width={width} height={height}>
                        <Layer>{backgroundImage && <Image image={backgroundImage} />}</Layer>
                        <Layer>
                            {data &&
                                data.map((r) => {
                                    return <IslandKonvaImage data={r} image={imgFront} key={uuidv4()} callback={(name) => handleCallback(name)} />;
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>
        </Box>
    );
}
