import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import IslandMap from "./island/IslandMap";
import SideManu from "./island/sidebar/SideManu";
import SuperviotConstants from "./SuperviotConstants";
import AppContext from "Components/AppContext";

export default function SuperviotMain() {
    const [data, setData] = useState();
    const [selectedName, setSelectedName] = useState("");

    const ctx = useContext(AppContext);
    ctx.updateTitle("Superviot");

    useEffect(() => {
        // console.log("uef in SuperviotMain")
        setData(SuperviotConstants.data);
    }, [selectedName]);

    const handleName = (name) => {
        // console.log("hadleName in SuperviotMain");
        setSelectedName(name);
    };

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2, display: "flex" }}>
            <IslandMap data={data} callback={(name) => handleName(name)} />
            <SideManu name={selectedName} callback={() => setSelectedName("")} />
        </Box>
    );
}
