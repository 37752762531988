const AlertsColumn = {
    getColumns: function (t, style) {
        const columns = [
            {
                field: "level",
                headerName: "Level",
                flex: 0.4,
                editable: false,
                sortable: true,
                filterable: false,
                renderCell: (params) => {
                    let labelStyle = style.default;
                    switch (params.value) {
                        case "WARNING":
                            labelStyle = style.warning;
                            break;
                        case "CRITICAL":
                            labelStyle = style.critical;
                            break;
                        default:
                            break;
                    }
                    return <span className={"badge " + style.label + " " + labelStyle}>{params.value}</span>;
                },
            },
            {
                field: "message",
                headerName: "Message",
                flex: 1.5,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "fixedName",
                headerName: "Thing",
                flex: 0.7,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "comment",
                headerName: "Comment",
                flex: 1,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "creationDate",
                headerName: "Creation Date",
                flex: 0.7,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "lastUpdate",
                headerName: "Last update",
                flex: 1,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "state",
                headerName: "State",
                flex: 0.4,
                editable: false,
                sortable: false,
                renderCell: (params) => {
                    let stateStyle = style.default;
                    if (params.value != undefined) {
                        switch (params.value.toUpperCase()) {
                            case "OPENED":
                                stateStyle = style.critical;
                                break;
                            case "RESOLVED":
                                stateStyle = style.success;
                                break;
                            case "IN_PROGRESS":
                                stateStyle = style.in_progress;
                                break;
                            case "IGNORED":
                                stateStyle = style.ignored;
                                break;
                            default:
                                break;
                        }
                    }
                    return <span className={"badge " + style.label + " " + stateStyle}>{params.value == undefined ? "" : params.value}</span>;
                },
            },
            {
                field: "username",
                headerName: "User name",
                flex: 1,
                editable: false,
                sortable: true,
                filterable: false,
            },
        ];
        return columns;
    },
};

export default AlertsColumn;
