import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import UtilService from "Services/utilService";
import StarRateIcon from "@mui/icons-material/StarRate";

export default function AttributeWidget({ variant, title, entity, thingId, attributeId, callback }) {
    const [fontColor, setFontColor] = useState();

    useEffect(() => {
        // console.log("uef in Co2UI");
        // console.log(entity);

        if (!entity) {
            return;
        }

        if (variant == "co2") {
            if (entity.value < 600) {
                setFontColor("#70AD47");
            } else if (600 <= entity.value && entity.value < 1200) {
                setFontColor("#FFC000");
            } else {
                setFontColor("#FF0000");
            }
        } else {
            setFontColor("#70AD47");
        }
    }, [entity]);

    const handleCallback = (e) => {
        if (callback && variant != "favorite") {
            callback(e, thingId, attributeId);
            callback(thingId, attributeId);
        }
    };

    return (
        <div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {variant == "favorite" && (
                    <div onClick={(e) => callback(e, thingId, attributeId)} style={{ cursor: "pointer" }}>
                        <StarRateIcon />
                    </div>
                )}
                {title}
            </Box>
            <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 0 }}>
                {entity && (
                    <IconButton onClick={(e) => handleCallback(e)} sx={{ color: fontColor }}>
                        {entity.value} {entity.unit}
                    </IconButton>
                )}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ textAlign: "center" }}>
                {entity && entity.date && UtilService.formatMDHma(entity.date)}
            </Typography>
        </div>
    );
}
