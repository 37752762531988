const SuperviotConstants = {
    PRODUCT_HYDROLAB_HL4: "082651c1-3042-4f32-9d85-cf10e056e150",

    DEFAULT: "#1A6082", // light blue for deffault background

    RED: "#FF0000",
    ORANGE: "#FFC000",
    YELLOW: "#FFFF00",
    GREEN: "#92D050",
    BLUE: "#8DB4E2",
    WHITE: "#FFFFFF",    

    toString: function (color) {
        switch (color) {
            case this.RED:
                return "RED";
            case this.ORANGE:
                return "ORANGE";
            case this.YELLOW:
                return "YELLOW";
            case this.GREEN:
                return "GREEN";
            case this.BLUE:
                return "BLUE";
            default:
                return "WHITE";
        }
    },

    data: [
        createData("SREINE", 200, 100, "8fb54d37-32c4-4295-b12b-fa335c82fedb"),
        createData("FFATP", 400, 200, "ad292753-6d40-46a4-936b-bc88a4ae76f6"),
        createData("FFATTG", 250, 300, "ba7cb513-d34d-4aa1-99e4-b9a988585ca1"),
        createData("FMPO", 500, 400, "faf11403-a43c-4f90-87fb-23d4c16c72b1"),
    ],

    default: {
        "d2cd1a0f-5b67-4c79-ad30-11964016c772": {
            comment: "Nitrates - NO3",
            marks: {
                60: "",
                50: "50",
                25: "25",
                10: "10",
                2: "2",
                0: "0",
            },
            value: [60, 50, 25, 10, 2, 0],
            min: 0,
            max: 60,
        },
        "32838aa3-d63f-45f1-9f5c-ef37ee675743": {
            comment: "O2",
            marks: {
                0: "",
                3: "3",
                4: "4",
                6: "6",
                8: "8",
                10: "",
            },
            value: [0, 3, 4, 6, 8, 10],
            max: 10,
        },
        "27556863-7744-4097-b669-7f3fddc03e5e": {
            comment: "Turbidite",
            marks: {
                "-1": "",
                0: "0",
                2: "2",
                5: "5",
                10: "10",
                12: "",
            },
            value: [-1, 0, 2, 5, 10, 12],
            min: -1,
            max: 12,
            step: 1,
        },
        "bde3e8b2-f4e2-4eeb-9822-1c1f3449969f": {
            comment: "Conductivité",
            marks: {
                0: "",
                100: "100",
                900: "900",
                10000: "10000",
                20000: "20000",
                21000: "21000",
            },
            value: [0, 100, 900, 10000, 20000, 21000],
            min: 0,
            max: 21000,
            // step: 10
        },
        "78243c45-30f4-4e44-b3db-89b53cf2e134": {
            comment: "pH",
            marks: {
                3: "3",
                4.5: "4.5",
                5.5: "5.5",
                6.6: "6.6",
                7: "7",
                8: "8",
                8.5: "8.5",
                9.5: "9.6",
                10: "10",
                12: "12",
            },
            value: [3, 4.5, 5.5, 6.5, 7, 8, 8.5, 9.5, 10, 12],
            min: 3,
            max: 12,
            step: 0.5,
        },
        "c2a5b632-35cb-4f62-aaa3-be41552bc449": {
            comment: "Redox",
            marks: {
                0: "",
                300: "300",
                600: "",
            },
            value: [0, 300, 600],
            min: 0,
            max: 600,
            step: 10,
        },
    },
};

function createData(name, x, y, thingId) {
    return { name, position: createPosition(x, y), thingId };
}

function createPosition(x, y) {
    return { x, y };
}

export default SuperviotConstants;
