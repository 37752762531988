import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import LogService from "./LogService";
import dayjs from "dayjs";
import { LogColumns } from "./LogColumns";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo } from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function LogMain() {
    const { t, i18n } = useTranslation();

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "entryDate", sort: "desc" }]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const currentDate = dayjs();
    const oneMonthBefore = currentDate.subtract(1, "days");

    const [start, setStart] = useState(oneMonthBefore);
    const [end, setEnd] = useState(currentDate);

    useEffect(() => {
        // console.log("useEffect in LogMain");

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);
            const asssytemCompanyID = "4495f4c7-601b-4671-b33f-71d26dcfbdd8";
            LogService.requestLogs(pagination.page, pagination.pageSize, setRowCount, sort, start.unix(), end.unix(), asssytemCompanyID, setRows).then(
                (r) => {}
            );
        });
    }, [pagination.page, pagination.pageSize, sort, start, end]);

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
                {/* <ExportMenu callback={handleReport} /> */}
                {/* <ToggleButton callback={handleToggle} on={on}></ToggleButton> */}
                {/* <DeleteButton callback={() => setRefresh(moment())} selectionModel={selectionModel}></DeleteButton> */}
            </GridToolbarContainer>
        );
    }
    const handleColumnOrderChange = (param) => {};

    const columns = LogColumns(t);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={0}>
                <DataGridPro
                    columns={columns}
                    rows={rows}
                    rowCount={rowCount}
                    disableColumnFilter
                    pagination={true}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sort}
                    paginationModel={pagination}
                    onPaginationModelChange={setPagination}
                    pageSizeOptions={[20, 50]}
                    onSortModelChange={setSort}
                    style={{ height: "calc(100vh - 80px)" }}
                    // slots={{
                    //     toolbar: CustomToolbar,
                    //     loadingOverlay: LinearProgress,
                    // }}
                    checkboxSelection
                    // onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                    density="compact"
                    onCellClick={handleCellClick}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    sx={{
                        "& .MuiTablePagination-selectLabel": {
                            marginTop: 2,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            marginTop: 2,
                        },
                    }}
                    columnVisibilityModel={{
                        id: false,
                        type: false
                    }}
                    onColumnOrderChange={handleColumnOrderChange}
                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                />
            </Grid>
        </Box>
    );
}
