import React, { useState, useEffect, useRef } from "react";
import { Text, Image, Rect, Group } from "react-konva";
import IslandService from "./IslandService";

export default function IslandKonvaImage({ data, image, callback }) {
    const [state, setState] = useState({
        isDragging: false,
        x: 0,
        y: 0,
    });

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipX, setTooltipX] = useState(0);
    const [tooltipY, setTooltipY] = useState(0);
    const [tooltipText, setTooltipText] = useState();
    
    const [tooltipHeight, setTooltipHeight] = useState(0);
    const [tooltipWidth, setTooltipWidth] = useState(180);

    const tooltipRef = useRef();

    useEffect(() => {
        // console.log("useEffect in IslandKonvaImage");
        // console.log(data);

        if (data) {
            let position = data.position;

            if (position) {
                setState({
                    isDragging: false,
                    x: position.x,
                    y: position.y,
                });

                setTooltipX(position.x);
                setTooltipY(position.y);
            }

            IslandService.requestThing(data.thingId).then((ctx) => {
                if (ctx) {
                    let arrText = [];
                    let height = 0;

                    ctx.forEach((e) => {
                        arrText.push(`${e.name}: ${e.measurement} ${e.unit}`);
                        height += 16;
                    });

                    setTooltipHeight(height);
                    setTooltipText(arrText.join("\n"));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.zIndex(100); // Set a high zIndex value to bring it to the top
        }
    }, [tooltipVisible]);

    const handleClick = (e) => {
        // console.log(e);
        // console.log(data)
        callback(data.name);
    };

    return (
        <>
            <Image
                image={image}
                x={state.x}
                y={state.y}
                width={50}
                height={50}
                draggable={false}
                onDragStart={() => {
                    setState({
                        isDragging: true,
                    });
                }}
                onDragEnd={(e) => {
                    setState({
                        isDragging: false,
                        x: e.target.x(),
                        y: e.target.y(),
                    });

                    setTooltipX(e.target.x());
                    setTooltipY(e.target.y());

                    // callbackXY(e.target.x() + 50, e.target.y());
                }}
                onMouseEnter={(e) => {
                    // console.log(e);
                    // tooltipRef.current.zIndex(27);
                    setTooltipVisible(true);
                }}
                onMouseOut={(e) => {
                    // console.log(e);
                    setTooltipVisible(false);
                }}
                onClick={(e) => handleClick(e)}
            />
            <Group x={tooltipX - 23} y={tooltipY + 60}>
                <Rect fill="black" opacity={0.75} cornerRadius={5} width={100} height={25} />
                <Text text={data.name} fill="white" padding={5} fontSize={14} align="center" drawBorder={true} width={100} />
            </Group>

            {tooltipVisible && (
                <Group ref={tooltipRef} x={tooltipX + 60} y={tooltipY}>
                    <Rect fill="black" opacity={0.75} cornerRadius={5} width={tooltipWidth} height={tooltipHeight} />
                    <Text text={tooltipText} fill="white" padding={5} fontSize={14} align="left" width={tooltipWidth} />
                </Group>
            )}
        </>
    );
}
