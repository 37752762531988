import { useState, useEffect, useContext } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import templateLorawanCsvFile from "Assets/template/things_v2.csv";
import templateLorawanXlsFile from "Assets/template/things_v2.63d30577.xls";
import templateCellularCsvFile from "Assets/template/Template-Cellular.csv";
import templateCellularXlsFile from "Assets/template/Template-Cellular.d5e30dea.xls";
import ImportService from "Services/importService";
import SettingContext from "Providers/SettingContext";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import UtilService from "Services/utilService";
import _ from "underscore";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Item from "../../../components/ui/Item";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import imgTech from "Assets/icons/tech_lorawan.png";
import imgCellular from "Assets/icons/tech_cellular.png";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default function ImportTool({ openImport, setOpenImport }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [step, setStep] = useState(0);

    let stepNames = ["Technology", "Import", "Check", "Import"];
    if (i18n.language === "fr") {
        stepNames = ["Technologie", "Importer", "Vérification", "Import"];
    }

    const [technology, setTechnology] = useState("lorawan");
    const [networkOn, setNetworkOn] = useState(true);

    const [isFileSelected, setIsFileSelected] = useState(false);

    // const fileInput = createRef(null);

    const [lblText, setLblText] = useState("");
    const [userCompany, setUserCompany] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");

    const setting = useContext(SettingContext);

    const [registedCount, setRegistedCount] = useState(0);
    const [certificates, setCertificates] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            if (user !== undefined && !UtilService.isEmpty(user.authorities)) {
                setUserCompany(user.company);
                setLblText(t("file.choose_file"));

                if(user.authorities === "SUPER_ADMIN") {
                    setIsSuperAdmin(true);
                }
            }
        });
    }, [errorMessage, t]);

    const handleNext = () => {
        setErrorMessage("");
        if (step === 0) {
            setStep(1);
        } else if (step === 1) {
            if (isFileSelected) {
                setStep(2);
            } else {
                setErrorMessage(t("de.import_select_file"));
            }
        } else if (step === 2) {
            setIsSaving(true);

            // Check step, ready to import
            let company = userCompany;
            if (selectedCompany && selectedCompany !== "") {
                company = selectedCompany;
            }

            // console.log(userCompany);
            // console.log(selectedCompany);
            // console.log(company);

            let result = ImportService.uploadCSV(technology, selectedFile, company, networkOn);

            result.promise
                .then((msg) => {
                    console.log(msg);

                    setIsSaving(false);

                    if (msg.code && msg.code === "failure") {
                        setErrorMessage(msg.message);
                        return;
                    }

                    // upload a file
                    setRegistedCount(msg.registered);
                    if (msg.certificates) setCertificates(msg.certificates);

                    setStep(3);

                    setting.setGlobalSetting({
                        notifyStateChange: lblText,
                    });
                })
                .catch((msg) => {
                    setErrorMessage(msg.error);
                    setIsSaving(false);
                });
        }
    };

    const handleBack = () => {
        setStep((step) => {
            let newStep = step - 1;
            if (newStep === 0) {
                setErrorMessage("");
            } else if (newStep === 1) {
                setErrorMessage("");
            }
            return newStep;
        });
    };

    const handleNetwork = () => {
        setNetworkOn((state) => !state);
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            setIsFileSelected(true);
        } else {
            setSelectedFile(undefined);
            setIsFileSelected(false);
        }
        setErrorMessage("");
    };

    const handleClose = () => {
        setUserCompany("");
        setSelectedCompany("");
        setOpenImport(false);
        setStep(0);
        setSelectedFile(undefined);
        setErrorMessage();
        setIsFileSelected(false);
        setTechnology("lorawan");
    };

    const handleTechnology = (e) => {
        // console.log(e.target.value);
        setTechnology(e.target.value);
    };

    const handleDownload = () => {
        const zip = new JSZip();

        certificates.forEach((cert, index) => {
            zip.file(`${cert.IMEI}_private.key`, cert.IOT_PRIVATE_KEY);
            zip.file(`${cert.IMEI}_certificate.pem`, cert.IOT_CERTIFICATE_PEM);
            zip.file(`${cert.IMEI}_public_key.txt`, cert.IOT_PUBLIC_KEY);
        });

        zip.generateAsync({ type: "blob" }).then((content) => {
            // Use FileSaver.js to save the ZIP file
            saveAs(content, "Certificates.zip");
        });
    };

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openImport} maxWidth="sm" fullWidth>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {t("de.import_tool")}
            </BootstrapDialogTitle>

            <Box sx={{ m: 2 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <Item>
                            <Stepper activeStep={step} alternativeLabel style={{ width: "100%" }}>
                                {stepNames.map((label, index) => (
                                    <Step key={`${label}-${index}`}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Item>
                    </Grid>
                    {step === 0 && (
                        <Grid item xs={12} md={12}>
                            <RadioGroup value={technology} onChange={handleTechnology} name="technology-options">
                                <Grid container direction="column" spacing={2} sx={{ p: 4 }}>
                                    <Grid item>
                                        <FormControlLabel
                                            value="lorawan"
                                            control={<Radio />}
                                            label={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={imgTech} alt="Tech Icon" style={{ marginLeft: 8, height: 30 }} />
                                                </div>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value="cellular"
                                            control={<Radio />}
                                            label={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={imgCellular} alt="Cellular Icon" style={{ marginLeft: 8, height: 30 }} />
                                                </div>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    )}
                    {step === 1 && isSuperAdmin && (
                        <Grid item xs={12} md={12}>
                            <Item sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography variant="subtitle1" sx={{ width: 200 }}>
                                    {t("de.import_company")}
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={selectedCompany}
                                    onChange={(e) => setSelectedCompany(e.target.value)}
                                    fullWidth
                                    size="small"
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                    }}
                                />
                            </Item>
                        </Grid>
                    )}
                    {step === 1 && (
                        <Grid item xs={12} md={12}>
                            <Item sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end", mt: 2 }}>
                                <Typography variant="subtitle1" sx={{ display: "inline", marginRight: 1, marginTop: "auto", textAlign: "left" }}>
                                    {t("de.import_youcan")}{" "}
                                    {technology === "lorawan" ? (
                                        <>
                                            <a href={templateLorawanCsvFile} target="_blank" rel="noopener noreferrer">
                                                {t("de.import_csv_template")}
                                            </a>
                                            <span> </span>
                                            <a href={templateLorawanXlsFile} target="_blank" rel="noopener noreferrer">
                                                {t("de.import_xls_template")}
                                            </a>
                                            {t("de.import_to_create")}
                                        </>
                                    ) : (
                                        <>
                                            <a href={templateCellularCsvFile} target="_blank" rel="noopener noreferrer">
                                                {t("de.import_csv_template")}
                                            </a>
                                            <span> </span>
                                            <a href={templateCellularXlsFile} target="_blank" rel="noopener noreferrer">
                                                {t("de.import_xls_template")}
                                            </a>
                                            {t("de.import_to_create")}
                                        </>
                                    )}
                                </Typography>
                            </Item>

                            <Item sx={{ display: "flex", flexDirection: "row", pt: 0 }}>
                                <Typography variant="subtitle1" sx={{ whiteSpace: "noWrap", display: "flex", alignItems: "center" }}>
                                    {t("de.import_file")}
                                </Typography>
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%", pl: 2 }}>
                                    <label htmlFor="upload-photo">
                                        <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={handleFileChange} />

                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            component="span"
                                            sx={{ textTransform: "none", fontWeight: "light" }}
                                            size="small"
                                        >
                                            {t("file.choose_file")}
                                        </Button>
                                    </label>
                                    <label htmlFor="customFile">
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Typography noWrap sx={{ ml: 2 }}>
                                                    {selectedFile ? selectedFile.name : t("file.no_file")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </label>
                                </Box>
                            </Item>
                        </Grid>
                    )}
                    {step === 2 && (
                        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
                            {/* <Item sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <Typography variant="subtitle1">Successful check, click next to import</Typography>
                            </Item> */}

                            {technology === "lorawan" ? (
                                <Item sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                                    <Typography variant="subtitle1">{t("de.import_registration")} :</Typography>
                                    <FormGroup row sx={{ alignItems: "center" }}>
                                        <Typography variant="subtitle1" sx={{ pl: 2 }}>
                                            {t("btn.no")}
                                        </Typography>
                                        <FormControlLabel
                                            control={<Switch checked={networkOn} onChange={handleNetwork} name="checkedB" color="primary" />}
                                            sx={{ ml: 0, mr: 0 }}
                                        />
                                        <Typography variant="subtitle1">{t("btn.yes")}</Typography>
                                    </FormGroup>
                                </Item>
                            ) : (
                                <Item sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>&nbsp;</Item>
                            )}
                        </Grid>
                    )}
                    {step === 3 && (
                        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
                            <Item sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                {registedCount === 1 && <Typography variant="subtitle1">{t("de.registered_one")}</Typography>}
                                {registedCount > 1 && (
                                    <Typography variant="subtitle1">
                                        {t("de.registered_more")} '{registedCount}' {t("de.registered_devices")}.
                                    </Typography>
                                )}
                                {certificates.length > 0 && (
                                    <Typography variant="subtitle1" onClick={() => handleDownload()} sx={{ cursor: "pointer" }}>
                                        {t("de.download_files")}
                                    </Typography>
                                )}
                            </Item>
                        </Grid>
                    )}
                    {errorMessage && (
                        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <DialogActions>
                {step === 0 && (
                    <Button variant="contained" color="primary" style={{ textTransform: "unset" }} onClick={() => handleNext()}>
                        {t("btn.next")}
                    </Button>
                )}
                {step === 1 && (
                    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: "unset", marginRight: "10px" }}
                            onClick={() => handleBack()}
                            // disabled={btnBackDisableStep2}
                        >
                            {t("btn.back")}
                        </Button>
                        <Button variant="contained" color="primary" style={{ textTransform: "unset" }} onClick={() => handleNext()}>
                            {t("btn.next")}
                        </Button>
                    </Box>
                )}
                {step === 2 && (
                    <Box sx={{ width: "100%" }}>
                        {isSaving && (
                            <Box sx={{ width: "100%", marginBottom: 2 }}>
                                <LinearProgress />
                            </Box>
                        )}
                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset", marginRight: "10px" }}
                                onClick={() => handleBack()}
                                // disabled={btnBackDisableStep2}
                            >
                                {t("btn.back")}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "unset" }}
                                onClick={() => handleNext()}
                                // disabled={btnNextDisableStep2}
                            >
                                {t("btn.next")}
                            </Button>
                        </Box>
                    </Box>
                )}
            </DialogActions>
        </BootstrapDialog>
    );
}
