import { Box } from "@mui/material";
import AppContext from "Components/AppContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import SettingContext from "Providers/SettingContext";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChartGeneral from "../../../components/chart/ChartGeneral";
import SensorDetailService from "../island/chart/SensorDetailService";
import SviChartService from "./SviChartService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, CustomTabPanel } from "Components/ui/CustomTabPanel";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function SviChartMain() {
    const { t, i18n } = useTranslation();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);

    const { thingId } = useParams();
    const [thing, setThing] = useState();
    const [attributes, setAttributes] = useState();
    const [value, setValue] = useState(0);
    const [reload, setReload] = useState();

    useEffect(() => {
        // console.log("uef in SviChartMain");
        // console.log(thingId);

        SensorDetailService.requestThing(thingId).then((t) => {
            setThing(t);
            // console.log(t);

            SviChartService.requestAttributes(t, t.product.model.id, setAttributes);
        });

        setting.setGlobalSetting({
            appId: AppId.SUPERVIOT,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.SUPERVIOT).title);
    }, [thingId]);

    const handleChange = (e, newValue) => {
        setValue(newValue);
        setReload(moment().unix());
    };

    return (
        <Box sx={{ pt: 10, ml: 4, mr: 4, display: "flex", flexDirection: "column" }}>
            {thing && (
                <Box sx={{ flexGrow: 1, mt: 0, width: "100%" }}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{thing.fixedName}</span>
                    {/* <Typography variant="h6" gutterBottom component="div">
                        {thing.fixedName}
                    </Typography> */}
                </Box>
            )}

            <Box sx={{ pt: 2, borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {attributes &&
                        attributes.map((a, idx) => {
                            const name = a.name === "Battery" ? t("attr.battery") : a.name;
                            return <Tab key={idx} label={name} {...a11yProps(idx)} />;
                        })}
                </Tabs>
            </Box>

            <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                {attributes &&
                    attributes.map((a, idx) => {
                        return (
                            <CustomTabPanel value={value} index={idx}>
                                <ChartGeneral thingId={thingId} attributeId={a.id} parentReload={reload} showThreshold={a.name === "Battery" ? true : false} />
                            </CustomTabPanel>
                        );
                    })}
            </Box>
        </Box>
    );
}
