import React, { useEffect, useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";

import "font-awesome/css/font-awesome.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";

import AppContext from "./components/AppContext";
import AuthProvider from "Providers/AuthProvider";

import SettingContext from "Providers/SettingContext";

import Header from "./components/header/Header";

// Code-splitting is automated for `routes` directory
import Home from "Routes/home";
import TemperatureMonitoring from "Routes/temperatureMonitoring";
import TempDetailMain from "Routes/temperatureMonitoring/detail/TempDetailMain";

import SensDc from "Routes/sensDc";
import SensDcAlerts from "Routes/sensDc/alerts/SensDcAlerts";

// Water Management
import WaterManagement from "Routes/waterManagement";
import WaterManagementDetail from "Routes/waterManagement/detail/Detail";

// Device Scanner
import DeviceScan from "Routes/deviceScan/scan";
import SensorFound from "Routes/deviceScan/sensorFound";
import SensorNotFound from "Routes/deviceScan/sensorNotfound";
import DeviceList from "Routes/deviceScan/list";

// Smart Agriculture
import SmartAgriculture from "Routes/smartAgriculture/SmartAgriculture";
import SmartAgricultureTable from "Routes/smartAgriculture/table/Dashboard";
import SmartAgricultureDetail from "Routes/smartAgriculture/details/Details";
import SmartAgricultureDetailMulti from "Routes/smartAgriculture/details/DetailMulti";
import SmartAgriculturePlantGrowth from "Routes/smartAgriculture/plant/Growth";
import SmartAgricultureCrop from "Routes/smartAgriculture/crop/Analysis";
import NotificationSetting from "Routes/smartAgriculture/notification/NotificationSetting";

// Emera V1
import EmeraDashboard from "Routes/emera_v1/Dashboard";
import EmeraDashboardDetail from "Routes/emera_v1/details/Details";
import EmeraAnalysis from "Routes/emera_v1/analysis/Analysis";
import EmeraAlerts from "Routes/emera_v1/EmeraAlerts";
import EmeraReports from "Routes/emera_v1/Report";
import Floor from "Routes/emera_v1/floor/Floor";
import ChartCo2 from "Routes/emera_v1/ChartCo2";
import ChartHumidity from "Routes/emera_v1/ChartHumidity";
import ChartTemp from "Routes/emera_v1/ChartTemp";

// Emera V2, Multi Metering
import MmEmeraDashboard from "Routes/emera_v2/Dashboard";
import MmEmeraDashboardDetail from "Routes/emera_v2/details/Details";
import MmEmeraAnalysis from "Routes/emera_v2/analysis/Analysis";
import MmEmeraAlerts from "Routes/emera_v2/EmeraAlerts";
import MmEmeraReports from "Routes/emera_v2/reports/Report";
import MmFloor from "Routes/emera_v2/floor/Floor";
import MmChartElectricity from "Routes/emera_v2/card_electricity/ChartElectricityMain";
import MmChartTemp from "Routes/emera_v2/card/ChartTemp";
import MMChartGeneral from "Routes/emera_v2/card/ChartGeneralMain";

// Superviot
import SuperviotMain from "Routes/superviot/SuperviotMain";
import DetailMain from "Routes/superviot/island/DetailMain";
import LogMain from "Routes/superviot/logs/LogMain";
import SviSettingMain from "Routes/superviot/setting/SviSettingMain";
import AlarmMain from "Routes/superviot/alarm/AlarmMain";
import SviChartMain from "Routes/superviot/charts/SviChartMain";

// Compost
import CompostMain from "Routes/compost/CompostMain";
import CompostMapMain2 from "Routes/compost/compost/CompostMapMain";
import SiChartGeneralMain2 from "Routes/compost/compost/chart/ChartGeneralMain";

// Device Explorer
import DeviceExplorer from "Routes/deviceExplorer/DeviceExplorer";
import SenditContainer from "Routes/deviceExplorer/share/SenditContainer";
import Catalog from "Routes/deviceExplorer/catalog/Catalog";
import CertificateDownload from "Routes/deviceExplorer/certificate/CertificateDownload";
import MeasurementMain from "Routes/deviceExplorer/favorite/MeasurementMain";
import GatewayMain from "Routes/deviceExplorer/gateway/GatewayMain";
import UserList from "Routes/deviceExplorer/users/UserList";
import CompanyList from "Routes/deviceExplorer/users/CompanyList";

// Agriculture Water
import IrrigationMain from "Routes/agriWater/irrigation/IrrigationMain";
import AgriWaterMain from "Routes/agriWater/AgriWaterMain";
import AgriWaterDetail from "Routes/agriWater/details/Details";

// Biz
import BizMain from "./routes/biz/BizMain";

import DashboardMenu from "Routes/dashboardMenu";
import Dashboard from "Routes/dashboard";

// Water Metering : H2O Mobile
import WaterMetering from "Routes/waterMetering/WaterMetering";
import AssignMain from "Routes/waterMetering/assign/AssignMain";
import CompanyList2 from "Routes/waterMetering/users/CompanyList";

// LoginPage
import LoginPage from "./components/login/LoginPage";
import FogotPassword from "./components/login/ForgotPassword";

import Protected from "./components/Protected";

// Boilerplate
import BoilerPlateContainer from "./components/boiler/BoilerPlateContainer";

import "Locales/i18n";
import i18n from "./locales/i18n";

// Theme
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import UserService from "./services/userService";

// Amplify
import { Amplify, Hub } from "aws-amplify";
// import "@aws-amplify/ui-react/styles.css";
import Auth from "@aws-amplify/auth";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App() {
    const [title, setTitle] = useState();
    const [globalSetting, setGlobalSetting] = useState({
        temperatureMonitoring: {
            celsius: true,
            language: "en",
        },
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState();

    const [locale, setLocale] = useState("enUS");
    const theme = useTheme();

    const themeWithLocale = useMemo(() => createTheme(theme, locales[locale]), [locale, theme]);

    useEffect(() => {
        // console.log("uef in app.js");
        Hub.listen("auth", (e) => {
            // console.log("auth event", e);

            if (e.payload.event == "signIn") {
                setCurrentUser(e.payload.data);
                setIsLoggedIn(true);

                UserService.getAuthenticatedUser().then((u) => {
                    if (u && u.locale) {
                        i18n.changeLanguage(u.locale);

                        if (u.locale === "fr") {
                            setLocale("frFR");
                        } else {
                            setLocale("enUS");
                        }
                    }
                });
            } else if (e.payload.event == "signOut") {
                setCurrentUser(undefined);
                setIsLoggedIn(false);
            }
        });

        Auth.currentAuthenticatedUser()
            .then((u) => {
                // console.log(u);
                setIsLoggedIn(true);
                setCurrentUser(u);
            })
            .catch((e) => {
                console.error(e);
                setIsLoggedIn(false);
                setCurrentUser(undefined);
            });
    }, []);

    return (
        <div id="app" style={{ height: "100vh", overflow: "none" }}>
            <AuthProvider onUserLoaded={onUserLoaded}>
                <AppContext.Provider value={{ title, updateTitle }}>
                    <SettingContext.Provider value={{ globalSetting, setGlobalSetting }}>
                        <ThemeProvider theme={themeWithLocale}>
                            <Router>
                                <Header isLoggedIn={isLoggedIn} />
                                <Switch>
                                    <Route exact path="/forgot/:email" children={<FogotPassword />} />
                                    <Route excat path="/login" children={<LoginPage />} />
                                    <Route
                                        exact
                                        path="/apps/dashboards"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <DashboardMenu />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/dashboards/:id"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <Dashboard />
                                            </Protected>
                                        }
                                    />

                                    <Route exact path="/" children={isLoggedIn ? <Home /> : <LoginPage />} />
                                    <Route exact path="/apps" children={isLoggedIn ? <Home /> : <LoginPage />} />

                                    {/* Temperature Monitoring */}
                                    <Route
                                        exact
                                        path="/apps/temperature_monitoring"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <TemperatureMonitoring settingCelsius={true} />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/temperature_monitoring/detail/:thingId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <TempDetailMain />
                                            </Protected>
                                        }
                                    />

                                    {/* Device Scan */}
                                    <Route
                                        exact
                                        path="/apps/device_scan/scan"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <DeviceScan />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_scan/found"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SensorFound />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_scan/notfound"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SensorNotFound />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_scan/list"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <DeviceList />
                                            </Protected>
                                        }
                                    />

                                    {/* Smart Agriculture */}
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SmartAgriculture />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture/table"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SmartAgricultureTable />
                                            </Protected>
                                        }
                                    />
                                    {/* <Route exact path="/apps/smart_agriculture/detail" children={<SmartAgricultureDetail />} /> */}
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture/detail/:thingId/:thingName/:attributeId/:attributeName/:clusterName/:unit/:fixedName/:tabIndex"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SmartAgricultureDetail />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture/detailMulti/:clusterName/:fixedName_1/:thingName_1/:thingId_1/:attributeId_1/:fixedName_2/:thingName_2/:thingId_2/:attributeId_2/:tabIndex"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SmartAgricultureDetailMulti />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture/plant"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SmartAgriculturePlantGrowth />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture/crop"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SmartAgricultureCrop />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/smart_agriculture/notification"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <NotificationSetting />
                                            </Protected>
                                        }
                                    />

                                    {/* Emera */}
                                    <Route
                                        exact
                                        path="/apps/emera/v1"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <EmeraDashboard />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/detail"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <EmeraDashboardDetail />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/analysis"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <EmeraAnalysis />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/alerts"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <EmeraAlerts />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/reports"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <EmeraReports />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/floor/:name"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <Floor />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/chart_co2"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <ChartCo2 />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/chart_humidity"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <ChartHumidity />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v1/chart_temp"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <ChartTemp />
                                            </Protected>
                                        }
                                    />

                                    {/* Emera V2 (Multi Metering) */}
                                    <Route
                                        exact
                                        path="/apps/emera/v2"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmEmeraDashboard />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/detail"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmEmeraDashboardDetail />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/analysis"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmEmeraAnalysis />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/alerts"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmEmeraAlerts />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/reports"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmEmeraReports />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/floor/:name"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmFloor />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/chart_electricity/:thingId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmChartElectricity />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/emera/v2/chart/:thingId/:attributeId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MMChartGeneral />
                                            </Protected>
                                        }
                                    />

                                    <Route
                                        exact
                                        path="/apps/emera/v2/chart_temp"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MmChartTemp />
                                            </Protected>
                                        }
                                    />

                                    {/* Superviot */}
                                    <Route
                                        exact
                                        path="/apps/superviot"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SuperviotMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/superviot/detail/:placeName"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <DetailMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/superviot/logs"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <LogMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/superviot/setting"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SviSettingMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/superviot/alarms"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <AlarmMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/superviot/charts/:thingId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SviChartMain />
                                            </Protected>
                                        }
                                    />

                                    {/* Compost */}
                                    <Route
                                        exact
                                        path="/apps/compost"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <CompostMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/compost/field_map"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <CompostMapMain2 />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/compost/chart/:thingId/:attributeId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SiChartGeneralMain2 />
                                            </Protected>
                                        }
                                    />

                                    {/* Water Management */}
                                    <Route
                                        exact
                                        path="/apps/water_management"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <WaterManagement />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/water_management_detail"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <WaterManagementDetail />
                                            </Protected>
                                        }
                                    />

                                    {/* Device Explorer */}
                                    <Route
                                        exact
                                        path="/apps/device_explorer/:appId/main"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <DeviceExplorer />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/share/:appId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SenditContainer />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/catalog/manufacturerList"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <Catalog />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/certificate/download"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <CertificateDownload />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/measurement"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <MeasurementMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/gateways"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <GatewayMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/users"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <UserList />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/device_explorer/companies"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <CompanyList />
                                            </Protected>
                                        }
                                    />

                                    {/* Water Metering */}
                                    <Route
                                        exact
                                        path="/apps/water_metering/:appId"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <WaterMetering />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/water_metering/assign/things"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <AssignMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/water_metering/users/org"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <CompanyList2 />
                                            </Protected>
                                        }
                                    />

                                    {/* SensDC */}
                                    <Route
                                        exact
                                        path="/apps/sens_dc"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SensDc settingCelsius={true} />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/sens_dc/alerts"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <SensDcAlerts />
                                            </Protected>
                                        }
                                    />

                                    {/* Argi Water */}
                                    <Route
                                        exact
                                        path="/apps/agriculture_water/irrigation"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <IrrigationMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/agriculture_water"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <AgriWaterMain />
                                            </Protected>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/apps/agriculture_water/detail/:thingId/:thingName/:attributeId/:attributeName/:clusterName/:fixedName/:tabIndex/:scroll"
                                        children={
                                            <Protected isLoggedIn={isLoggedIn}>
                                                <AgriWaterDetail />
                                            </Protected>
                                        }
                                    />

                                    {/* Biz */}
                                    <Route exact path="/apps/biz" children={<BizMain />} />

                                    <Route exact path="/apps/boiler" children={<BoilerPlateContainer />} />
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </SettingContext.Provider>
                </AppContext.Provider>
            </AuthProvider>
        </div>
    );

    function updateTitle(title) {
        setTitle(title);
        document.title = title || "Sensor Apps";
    }

    function onUserLoaded(user) {
        let result;
        if (user.authorities) {
            if ((result = user.authorities.split(",").filter((authority) => authority.startsWith("APPLICATION_"))).length === 1) {
                result = result[0].split("_");
                result.shift(1);
                result = result.join("_").toLowerCase();
                // history.push(`/apps/${result}`) // redirect to /apps/[specific app] in spring
            }
        }
    }
}

const styles = {
    container: { width: 400, margin: "0 auto", display: "flex", flexDirection: "column", justifyContent: "center", padding: 20 },
    todo: { marginBottom: 15 },
    input: { border: "none", backgroundColor: "#ddd", marginBottom: 10, padding: 8, fontSize: 18 },
    todoName: { fontSize: 20, fontWeight: "bold" },
    todoDescription: { marginBottom: 0 },
    button: { backgroundColor: "black", color: "white", outline: "none", fontSize: 18, padding: "12px 0px" },
};

export default App;
// export default withAuthenticator(App);
