import { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import ApiService from "Services/apiService";
import _ from "underscore";
import SettingContext from "Providers/SettingContext";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/ui/BootstrapDialog";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function Confirm({ selectionModel, callback, open, setOpen }) {
    const [disableButtons, setDisableButtons] = useState(false);
    const setting = useContext(SettingContext);
    const [isLoading, setLoading] = useState(false); // State to manage loading state

    useEffect(() => {
        // console.log("useEffect in Confirm")
        // console.log(selectionModel)
        setDisableButtons(false);
    }, [isLoading]);

    const closeModal = (e) => {
        setOpen(false);
        // callback();
    };

    const handleDelete = () => {
        let totalRequest = selectionModel.length;

        if (selectionModel.length > 0) {
            setDisableButtons(true);
            setLoading(true);

            // console.log("selectionModel...");
            // console.log(selectionModel);
            let allWaitRequest = deleteThings(selectionModel);

            Promise.all(allWaitRequest)
                .then((result) => {
                    _.each(result, (r) => {
                        if (r == "ok") {
                            totalRequest -= 1;
                        }
                    });

                    closeModal();
                    callback();

                    // If this request back to the server with no time delay, the server will return old data.
                    // The MeasurementView will request again when the setting change
                    setTimeout(() => {
                        if (totalRequest == 0) {
                            // delete all rows that we requested
                            setting.setGlobalSetting({
                                notifyStateChange: "deleted",
                            });
                        }
                        setLoading(false);
                    }, 1000);
                })
                .catch((error) => {
                    console.error("Error deleting things:", error);
                    setLoading(false); // Ensure loading state is reset even on error
                });
        }
    };

    return (
        <BootstrapDialog onClose={closeModal} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={closeModal}>
                Remove a meter
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Typography gutterBottom>
                    {selectionModel && selectionModel.length == 0 && "Select meters to remove"}
                    {selectionModel && selectionModel.length > 0 && `Removing a meter would remove historical data as well.`}
                </Typography>
                <Typography gutterBottom>{selectionModel && selectionModel.length > 0 && `This action could not be revert.`}</Typography>
                <Typography gutterBottom>
                    {selectionModel && selectionModel.length > 0 && `Do you confirm to remove ${selectionModel.length} meters ?`}
                </Typography>
                {isLoading && (
                    <Box sx={{ flexGrow: 1, width: "90%", m: 2, border: "1px solid red" }}>
                        <LinearProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {selectionModel && selectionModel.length > 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: "#5694B5",
                            color: "#FFFFFF",
                            textTransform: "none",
                            cursor: isLoading ? "wait" : "pointer",
                        }}
                        onClick={handleDelete}
                        disabled={disableButtons}
                    >
                        Yes
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px", backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }}
                    onClick={closeModal}
                    disabled={disableButtons}
                >
                    No
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

function deleteThings(thingIds) {
    let allPromise = [];
    _.each(thingIds, (thingId) => {
        let p = new Promise((resolve, reject) => {
            let requestOptions = {
                url: "/api/things/" + thingId + "?force=true",
                data: "",
            };
            // console.log(requestOptions);

            ApiService.deleteData(requestOptions)
                .then((msg) => {
                    // console.log(msg);
                    if (msg == "ok") {
                        resolve(msg);
                    } else {
                        reject(msg);
                    }
                })
                .catch((e) => console.log(e));
        });
        allPromise.push(p);
    });

    return allPromise;
}
