import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Item from "../../components/ui/Item";
import SuperviotConstants from "../superviot/SuperviotConstants";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import { AppId } from "Components/applications/ApplicationData";
import CompostMapMain from "./compost/CompostMapMain";

export default function CompostMain() {
    const [data, setData] = useState();
    const [selectedName, setSelectedName] = useState("");

    const setting = useContext(SettingContext);
    const ctx = useContext(AppContext);
    ctx.updateTitle("Compost");

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.COMPOST,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        // console.log("uef in CompostMain")
        setData(SuperviotConstants.data);
    }, []);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Item>
                        <CompostMapMain />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}
