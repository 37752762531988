import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import SuperviotConstants from "../SuperviotConstants";
import ChartGeneral from "./chart/ChartGeneral";
import Alerts from "./details/Alerts";

export default function DetailMain(params) {
    const { placeName } = useParams();

    const [thingId, setThingId] = useState();
    const [attributeId, setAttributeId] = useState();

    useEffect(() => {
        // console.log("useEffect in DetailMain");
        // console.log(placeName);

        const entity = SuperviotConstants.data.find((d) => d.name == placeName);
        // console.log(entity);

        setThingId(entity.thingId);
        setAttributeId(entity.attributeId);
    }, []);

    return (
        <Box sx={{ flexGrow: 1, marginTop: 0 }}>
            <Grid container spacing={2} sx={{ pl: 5, pr: 5 }}>
                <Grid item xs={12}>
                    <ChartGeneral thingId={thingId} attributeId={attributeId} placeName={placeName} />
                </Grid>
                <Grid item xs={12} >
                    <Alerts thingId={thingId} attributeId={attributeId} />
                </Grid>
            </Grid>
        </Box>
    );
}
