import _ from "underscore";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const awsConfig = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
};
AWS.config.update(awsConfig);
const geocoder = new AWS.Location();

const AddrService = {
    verifyAddress: async (addr) => {
        if (!addr || addr == "") {
            return [];
        }

        const params = {
            IndexName: "explore.place.Esri",
            Text: addr,
            MaxResults: 10,
        };

        // console.log(params);
        const response = await geocoder.searchPlaceIndexForText(params).promise();

        // console.log(response);
        const mapped = response.Results.map((r) => {
            return {
                key: uuidv4(),
                label: r.Place.Label,
                ...r.Place,
            };
        });

        return mapped;
    },
};

export default AddrService;
